// actions.js
export const STORE_DATA = 'STORE_DATA';
export const RETRIEVE_DATA = 'RETRIEVE_DATA';

export const storeData = (data) => ({
    type: STORE_DATA,
    payload: data
});

export const retrieveData = () => ({
    type: RETRIEVE_DATA
});
