// reducers.js
import { STORE_DATA, RETRIEVE_DATA } from './actions';

const initialState = {
    data: null
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_DATA:
            return {
                ...state,
                data: action.payload
            };
        case RETRIEVE_DATA:
            // Retrieve data logic here
            return state;
        default:
            return state;
    }
};

export default rootReducer;
