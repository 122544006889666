import { Box, styled } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import Scrollbar from "components/ScrollBar";
import LayoutDrawer from "layouts/layout-parts/LayoutDrawer";
import MultiLevelMenu from "./MultiLevelMenu";
import Logo from './../../assets/images/logo-tq.png';

const TOP_HEADER_AREA = 200;
const NavWrapper = styled(Box)(() => ({
  paddingLeft: 16,
  paddingRight: 16,
  height: "100%",
  backgroundColor:'#154c79'
}));
const StyledLogo = styled(Box)(() => ({
  paddingLeft: 8,
  fontWeight: 700,
  fontSize: 20,
  backgroundColor:'#154c79'
}));

const MobileSidebar = props => {
  const {
    sidebarCompact,
    showMobileSideBar,
    setShowMobileSideBar
  } = props;
  return <LayoutDrawer open={showMobileSideBar} onClose={setShowMobileSideBar}>
      <Box p={2} mb={8} mt={2} pl={3} style={{backgroundColor:'#154c79'}}  maxHeight={TOP_HEADER_AREA}>
        <FlexBox ml={1.5} >
           <img src={Logo}  alt="logo" width={200} />

        </FlexBox>
      </Box>

      <Scrollbar autoHide clickOnTrack={false} sx={{
      overflowX: "hidden",
      maxHeight: `calc(90vh - ${TOP_HEADER_AREA}px)`
    }}>
        <NavWrapper compact={sidebarCompact}>
          <MultiLevelMenu sidebarCompact={false} />
        </NavWrapper>
      </Scrollbar>
    </LayoutDrawer>;
};

export default MobileSidebar;