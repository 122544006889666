import duotone from 'icons/duotone';
import { FcConferenceCall,FcSoundRecordingCopyright,FcShare,FcPortraitMode,FcSalesPerformance,FcPositiveDynamic,FcOpenedFolder,FcOnlineSupport,FcLibrary,FcServices,FcSurvey,FcRatings } from "react-icons/fc";


export const navigations = [
{
  name: 'Notifications',
  icon: FcServices,
  children: [{
    name: 'Inbox',
    path: '/dashboard/notification'
  }, {
    name: 'Archive',
    path: '/dashboard/archive'
  }]
},
{
  type: 'label',
  label: ''
}, {
  name: 'Training & Examination',
  icon: FcLibrary,
  children: [
{
 name: 'Examination',
 path: '/dashboard/saas'
}, {
 name: 'Certification',
 path: '/dashboard/saas'
}, {
 name: 'Training',
 path: '/dashboard/profile-v2',
   children: [{
 name: 'Candidate List',
 path: '/dashboard/can-list'
}, {
 name: 'New Candidate',
 path: '/dashboard/new-can'
},{
 name: 'Events',
 path: '/dashboard/event'
}, {
 name: 'Products',
 path: '/dashboard/products'


}, {
 name: 'Lecturer',
 path: '/dashboard/lecturer'


},


]


}

  ]
}, {
  name: 'Projects',
  path: '/dashboard/projects',
  icon: FcSoundRecordingCopyright,

}, {
  name: 'Contracts',
  icon: FcRatings,

   children: [{
 name: 'Suppliers',
 path: '/dashboard/suppliers'
}, {
 name: 'Clients',
 path: '/dashboard/clients'
}]
}, {
  name: 'Personnel',
  icon: FcPortraitMode,
  path: '/dashboard/profiles'

},  {
  name: 'Asset Management',
  icon: FcSurvey,
  children: [{
    name: 'Assets',
    path: '/dashboard/assets'
  }]
}, {
  name: 'Financial',
  icon: FcSalesPerformance,
  children: [{
    name: 'Records',
    path: '/dashboard/fiantialrecord'
  }]
}, {
  name: 'Client Feedback',
  icon: FcOnlineSupport,
  children: []
},
 {
  name: 'Statistics Page',
  icon: FcPositiveDynamic,
  children: []
},
{
  name: 'Knowledge Sharing',
  icon: FcShare,
  children: []

},
  {
  name: 'Documents Management',
  icon: FcOpenedFolder,
  children: [{
    name: 'QMS',
    path: '/dashboard/filelist'
  },{
    name: 'Archived',
    path: '/dashboard/archivedfilelist'
  },{
    name: 'Expired Files',
    path: '/dashboard/expiredfile'
  },{
    name: '',
    path: ''
  },]
},

 ];